<template>
  <v-row class="my-5" align="center">
    <v-col class="d-flex align-center">
      <h6 class="text-body-1 secondary--text mr-3">{{ title }}</h6>

      <slot name="action-buttons" />

      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>